import { FunctionComponent } from 'react';
import styles from './index.module.css';
import ReactMarkdown from 'react-markdown';
import Email from 'assets/img/new/Email.png';
import Tiktok from 'assets/img/new/Tiktok.png';
import Instagram from 'assets/img/new/Instagram.png';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useAppSelector } from '../../../redux/hooks';
import Logo from 'assets/svg/new/logo.svg';

interface IFooterProps {}
const Footer2: FunctionComponent<IFooterProps> = (props) => {
  const footer = useAppSelector((state) => state.layout.footer);
  const mainMenu = useAppSelector((state) => state.layout.mainMenu);

  const { column1, column2 } = { ...footer };
  const { socialLinks = [] } = { ...mainMenu };
  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.title}>
          <img src={Logo} alt="CXS" />
        </div>

        <div className={styles.footerContent}>
          {/* nav */}
          <div className={clsx(styles.footerSection, styles.footerNav)}>
            <ul className={styles.bottomNav}>
              <li className={styles.navbarLinkItem}>
                <NavLink
                  className={clsx(
                    styles.navbarLinkItemLink,
                    styles.navbarLinkItemLinkSignUp
                  )}
                  to={'/sign-up'}
                >
                  Sign Up
                </NavLink>
              </li>
              <li className={styles.navbarLinkItem}>
                <NavLink
                  className={clsx(styles.navbarLinkItemLink)}
                  to={'/login'}
                >
                  Login
                </NavLink>
              </li>
            </ul>
          </div>

          {/* addresses*/}
          <div className={clsx(styles.footerSection, styles.footerAddresses)}>
            <div className={styles.subtitle}>Addresses</div>
            <div className={styles.footerAddressesColumns}>
              {column1 && (
                <div>
                  <ReactMarkdown
                    children={column1}
                    components={{
                      h4: ({ node, ...props }) => (
                        <h4 className={styles.textH4} {...props}>
                          {props.children}
                        </h4>
                      ),
                    }}
                  />
                </div>
              )}
              {column2 && (
                <div>
                  <ReactMarkdown
                    children={column2}
                    components={{
                      h4: ({ node, ...props }) => (
                        <h4 className={styles.textH4} {...props}>
                          {props.children}
                        </h4>
                      ),
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          {/* contact */}
          <div className={clsx(styles.footerSection, styles.footerContact)}>
            <div className={styles.subtitle}>Contact</div>
            {!!socialLinks.length && (
              <div className={styles.socialLinksWrapper}>
                <ul className={styles.socialLinks}>
                  {socialLinks.map((socialLink, socialLinkIndex) => {
                    const { url, icon } = socialLink;

                    const slug =
                      icon === 'Mail'
                        ? url.split('mailto:').pop()
                        : url.split('/').pop();

                    const label =
                      icon === 'Mail'
                        ? slug
                        : slug?.startsWith('@')
                        ? slug
                        : `@${slug}`;

                    const image =
                      icon === 'Instagram'
                        ? Instagram
                        : icon === 'Tiktok'
                        ? Tiktok
                        : icon === 'Mail'
                        ? Email
                        : '';

                    return (
                      <li key={`social-${socialLinkIndex}`}>
                        <a
                          className={styles.socialLink}
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className={styles.socialIconWrapper}>
                            <img
                              src={image}
                              alt="social media"
                              className={styles.socialIcon}
                            />
                          </div>
                          <span>{label}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <div>
            <span className={styles.copyright}>
              <span className={styles.copyrightYear}>
                © Copyright SEEN Group 2022
              </span>
              <span className={styles.legalLinks}>
                <a
                  href="/legal/anti-slavery-statement"
                  target="_blank"
                  rel="noreferrer"
                >
                  Anti Slavery Statement
                </a>
                <a href="/legal/privacy-notice">Privacy Notice</a>
                <a
                  href="/legal/sustainability-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sustainability Policy
                </a>
                <a
                  href="/legal/sustainability-report"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sustainability Report
                </a>
                <a href="/legal/terms-of-use">Terms Of Use</a>
                <a href="/legal/cookie-policy" target="_blank" rel="noreferrer">
                  Cookie Policy
                </a>
              </span>
            </span>
          </div>
          <div>
            <span className={styles.copyright}>
              Registered in England & Wales as Beauty SEEN Ltd 20 Little
              Portland Street, London, W1W 8BS No. 06270260 VAT No. 912298817
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer2;

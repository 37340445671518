import { FunctionComponent, useEffect, useState } from 'react';
import styles from './index.module.css';
import { IPageHome } from 'types/generated/strapi';
import { getStrapiURL } from 'utils/urls';
import * as strapi from '@casbah/strapi-fetch';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import SeoHead from 'components/shared/SeoHead';
import MainMenu2 from 'components/shared/MainMenu2';
import Footer2 from 'components/shared/Footer2';
import thumbnail from 'assets/img/new/video-thumbnail.jpg';

const HomePage2: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageHome>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageHome>(getStrapiURL('/page-home'));
      if (resp.ok) setPageData(resp.payload);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const { seo } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu2 />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          <div className={styles.wrapper}>
            <img
              className={styles.thumbnail}
              src={thumbnail}
              alt="thumbnail"
              loading="eager"
            />
            <video
              className={styles.video}
              width="100%"
              height="100%"
              disablePictureInPicture
              playsInline
              muted
              autoPlay
              loop
              controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
              preload="metadata"
            >
              <source
                src={process.env.PUBLIC_URL + '/videos/CXS-3D-1000x540.mp4'}
                type="video/mp4"
                media="all and (max-width: 768px)"
              />
              <source
                src={process.env.PUBLIC_URL + '/videos/CXS-3D-1440x540.mp4'}
                type="video/mp4"
              />
            </video>
          </div>
        </>
      )}
      <Footer2 />
    </>
  );
};

export default HomePage2;
